import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { get, map, size } from 'lodash/fp'
import { mapIndexed } from 'helpers'

import { Layout } from 'src/components/Layout'

import { Affiliates } from 'src/slices/Affiliates'
import { BodyCopy } from 'src/slices/BodyCopy'
import { DetailedPageIntro } from 'src/slices/DetailedPageIntro'
import { FeaturedLinkBar } from 'src/slices/FeaturedLinkBar'
import { FeaturedPage } from 'src/slices/FeaturedPage'

const AboutPage = ({ data }) => {
  const page = get('file.childMarkdownRemark', data)
  const highlights = get('frontmatter.highlights', page)
  const affiliates = get(
    'affiliatesFile.childMarkdownRemark.frontmatter.affiliates',
    data
  )

  return (
    <Layout>
      <Helmet>
        <title>{get('frontmatter.title', page)}</title>
      </Helmet>
      <DetailedPageIntro
        imageFluid={get('frontmatter.image.childImageSharp.fluid', page)}
        pageTitle={get('frontmatter.title', page)}
        markdown={get('frontmatter.description', page)}
      />
      <FeaturedLinkBar
        heading="Contact Us"
        message="Visit us at one of our convenient locations."
        buttonText="View Locations"
        buttonUrl="/locations/"
        maxWidth="large"
      />
      <BodyCopy
        heading={get('frontmatter.subtitle', page)}
        markdown={get('rawMarkdownBody', page)}
      />
      {mapIndexed(
        ([i, highlight]) => (
          <FeaturedPage
            to={get('url', highlight)}
            title={get('title', highlight)}
            descriptionMarkdown={get('body', highlight)}
            imageFluid={get('image.childImageSharp.fluid', highlight)}
            imageSide={i % 2 ? 'right' : 'left'}
            mt={i > 0 ? [2, 4] : 0}
            mb={i >= size(highlights) - 1 ? 0 : [2, 4]}
          />
        ),
        highlights
      )}
      <Affiliates heading="Affiliates">
        {map(
          affiliate => (
            <Affiliates.Affiliate
              descriptionMarkdown={get('description', affiliate)}
              imageFluid={get('logo.childImageSharp.fluid', affiliate)}
              name={get('name', affiliate)}
              url={get('url', affiliate)}
            />
          ),
          affiliates
        )}
      </Affiliates>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query AboutUsPage {
    file(relativePath: { eq: "customPages/about-us.md" }) {
      childMarkdownRemark {
        frontmatter {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          subtitle
          highlights {
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            title
            body
            url
          }
        }
        rawMarkdownBody
      }
    }
    affiliatesFile: file(relativePath: { eq: "customPages/affiliates.md" }) {
      childMarkdownRemark {
        frontmatter {
          affiliates {
            name
            description
            url
            logo {
              childImageSharp {
                fluid(maxWidth: 500, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
